<template>

  <section class="register-2">
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-stretch auth auth-img-bg">
          <div class="row flex-grow">
            <div class="col-lg-6 d-flex align-items-center justify-content-center">
              <div class="auth-form-transparent text-left p-3">
                <div class="brand-logo">
                  <img src="../../../assets/images/logo-ailem.png" alt="logo">
                </div>
                <h4>Vous êtes nouveau?</h4>
                <h6 class="font-weight-light">Rejoignez-nous aujourd'hui! Cela ne prend que quelques étapes</h6>
                <form class="pt-3">
                  <div class="form-group">
                    <label>Nom d'utilisateur</label>
                    <div class="input-group">
                      <div class="input-group-prepend bg-transparent">
                        <span class="input-group-text bg-transparent border-right-0">
                          <i class="mdi mdi-account-outline text-primary"></i>
                        </span>
                      </div>
                      <input type="text" class="form-control form-control-lg border-left-0" placeholder="Nom d'utilisateur">
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Email</label>
                    <div class="input-group">
                      <div class="input-group-prepend bg-transparent">
                        <span class="input-group-text bg-transparent border-right-0">
                          <i class="mdi mdi-email-outline text-primary"></i>
                        </span>
                      </div>
                      <input type="email" class="form-control form-control-lg border-left-0" placeholder="Email">
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Pays</label>
                    <select class="form-control form-control-lg" id="exampleFormControlSelect2">
                      <option>Pays</option>
                      <option>France</option>
                      <option>Etats Unis</option>
                      <option>Angleterre</option>
                      <option>Suisse</option>
                      <option>Madagascar</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label>Mot de passe</label>
                    <div class="input-group">
                      <div class="input-group-prepend bg-transparent">
                        <span class="input-group-text bg-transparent border-right-0">
                          <i class="mdi mdi-lock-outline text-primary"></i>
                        </span>
                      </div>
                      <input type="password" class="form-control form-control-lg border-left-0" id="exampleInputPassword" placeholder="Mot de passe">
                    </div>
                  </div>
                  <!-- reCaptcha Google -->
                  <div class="form-group">
                    
                  </div>
                  <div class="mb-4">
                    <div class="form-check">
                      <label class="form-check-label text-muted">
                        <input type="checkbox" class="form-check-input"> J'accepte tous les termes et conditions </label>
                    </div>
                  </div>
                  <div class="mt-3">
                    <a class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" href="../../index.html">S'INSCRIRE</a>
                  </div>
                  <div class="text-center mt-4 font-weight-light"> Vous avez déjà un compte? <a href="login.html" class="text-primary">Se connecter</a>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-lg-6 register-half-bg d-flex flex-row">
              <p class="text-white font-weight-medium text-center flex-grow align-self-end">Copyright &copy; 2019 All rights reserved.</p>
            </div>
          </div>
        </div>
        <!-- content-wrapper ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </section>

</template>

<script>
export default {
  name: 'register-2'
}
</script>